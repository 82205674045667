import React from "react"
import TemplatePageEbook  from "@components/pageEbook/template"
import { Helmet } from "react-helmet"
import data from "@components/pageEbook/ebooks/ebook17/dataPageEbook17"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
      Tendencias de la comunicación digital en la educación en 2024 
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/ebook/comunicacion-digital-educacion/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Explora cómo las herramientas digitales transforman las metodologías de enseñanza y el acceso a la información en las aulas."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Tendencias de la comunicación digital en la educación en 2024"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/ebook/comunicacion-digital-educacion/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Explora cómo las herramientas digitales transforman las metodologías de enseñanza y el acceso a la información en las aulas."
      />
    </Helmet>
    <TemplatePageEbook
      ebook="Educación"
      location={location}
      typeEbook="ebook17"
      clickHereHref="https://beexcc.com/blog/telemarketing/"
      data={data}
    />
  </div>
)

export default IndexPage
